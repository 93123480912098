import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import SectionTitle from "../components/common/SectionTitle"
import SEO from "../components/seo"

const FeatureImageContainer = styled.div`
  width: 100%;
  height: 40vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledArticle = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
`

const ArticleTitle = styled.h1`
  max-width: 800px;
  font-family: "Orbitron", sans-serif;

  border-bottom: 3px solid red;
  padding: 1rem;
  margin: 1rem;

  font-weight: normal;
`

export const query = graphql`
  query($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      description
      featuredImage {
        fluid {
          src
        }
      }
      datePosted(formatString: "MMM Do, YYYY")
      body {
        json
      }
    }
  }
`

const ArticleTemplate = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }
  return (
    <>
      <SEO
        title={props.data.contentfulArticle.title}
        description={props.data.contentfulArticle.description}
      />
      <Layout>
        <FeatureImageContainer>
          <img
            src={props.data.contentfulArticle.featuredImage.fluid.src}
            alt={props.data.contentfulArticle.description}
          />
        </FeatureImageContainer>
        <StyledArticle>
          <ArticleTitle>{props.data.contentfulArticle.title}</ArticleTitle>
          <p>{props.data.contentfulArticle.datePosted}</p>
          <div>
            {documentToReactComponents(
              props.data.contentfulArticle.body.json,
              options
            )}
          </div>
        </StyledArticle>
      </Layout>
    </>
  )
}

export default ArticleTemplate
